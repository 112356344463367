<template>
  <div id="app">
    <div v-if="!submitted">
      <form id="new_contact" class="new_contact" @submit.prevent="submit()">
        <ValidationObserver ref="observer">
          <input name="utf8" type="hidden" value="✓" />
          <div class="contact-form-wrapper">
            <div class="contact-form">
              <div class="row row--spaced-xs">
                <div class="col-md-4">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="jméno a příjmení"
                  >
                    <div
                      class="form-group adaptive-placeholder string required name"
                      :class="{ 'has-error': errors[0] }"
                    >
                      <div class="form-control-wrapper">
                        <input
                          id="name"
                          v-model="form.name"
                          class="form-control empty string required"
                          type="text"
                          name="contact[name]"
                          :class="{ 'not-empty': form.name }"
                        />
                        <label
                          class="control-label string required"
                          tag="div"
                          for="name"
                        >
                          <abbr title="required">*</abbr> Jméno a příjmení
                        </label>
                        <span class="input-error help-block">{{
                          errors[0]
                        }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="e-mailová adresa"
                  >
                    <div
                      class="form-group adaptive-placeholder email optional email"
                      :class="{ 'has-error': errors[0] }"
                    >
                      <div class="form-control-wrapper">
                        <input
                          id="email"
                          v-model="form.email"
                          class="form-control empty string email optional"
                          type="email"
                          name="contact[email]"
                          :class="{ 'not-empty': form.email }"
                        />
                        <label
                          class="control-label email optional"
                          tag="div"
                          for="email"
                          >E-mailová adresa</label
                        >
                        <span class="input-error help-block">{{
                          errors[0]
                        }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="telefnoní číslo"
                  >
                    <div
                      class="form-group adaptive-placeholder tel optional phone"
                      :class="{ 'has-error': errors[0] }"
                    >
                      <div class="form-control-wrapper">
                        <input
                          id="phone"
                          v-model="form.phone"
                          class="form-control empty string tel optional"
                          type="tel"
                          name="contact[phone]"
                          :class="{ 'not-empty': form.phone }"
                        />
                        <label
                          class="control-label tel optional"
                          tag="div"
                          for="phone"
                          >Telefnoní číslo</label
                        >
                        <span class="input-error help-block">{{
                          errors[0]
                        }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-8">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="zpráva"
                  >
                    <div
                      class="form-group adaptive-placeholder text required message"
                      :class="{ 'has-error': errors[0] }"
                    >
                      <div class="form-control-wrapper">
                        <textarea
                          id="message"
                          v-model="form.message"
                          class="form-control empty text required"
                          rows="6"
                          name="contact[message]"
                          :class="{ 'not-empty': form.message }"
                        >
                        </textarea>
                        <label
                          class="control-label text required"
                          tag="div"
                          for="message"
                        >
                          <abbr title="required">*</abbr> Zpráva
                        </label>
                        <span class="input-error help-block">{{
                          errors[0]
                        }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                  <div class="text-left">
                    <Gdpr identifier="contact" />
                  </div>
                </div>
              </div>
              <div class="form-group--btn">
                <input
                  type="submit"
                  name="commit"
                  value="Odeslat kontaktní formulář"
                  class="btn btn-primary"
                />
              </div>
            </div>
          </div>
        </ValidationObserver>
      </form>
    </div>
    <div v-else>
      <h2>Děkujeme, zpráva k nám už je na cestě!</h2>
    </div>
  </div>
</template>

<script>
import Gdpr from "components/Gdpr.vue";

export default {
  name: "ContactForm",
  components: {
    Gdpr
  },
  data: () => ({
    submitted: false,
    submitError: null,
    form: {
      name: "",
      email: "",
      phone: "",
      message: ""
    }
  }),
  methods: {
    async submit() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.submitError = null;
        this.axios.defaults.headers.common[
          "X-CSRF-Token"
        ] = document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content");

        this.axios
          .post("/api/v1/contacts", { contact: this.form })
          .then(() => {
            this.submitted = true;
          })
          .catch(() => {
            this.submitError = true;
          });
      }
    }
  }
};
</script>
