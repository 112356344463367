<template>
  <div>
    <button class="modal__close" @click.prevent="$emit('close')">
      X
    </button>
    <div class="modal-body">
      <h3>Souhlas s poskytnutím a zpracováním osobních údajů</h3>
      <p>
        Uděluji tímto dobrovolně svůj souhlas s poskytnutím a zpracováním
        osobních údajů dle Nařízení EU 679/2016 v rozsahu údajů obsažených v
        tomto formuláři, tj. zejména jméno, příjmení, e-mailová adresa a telefon
        správcem, tj. Hana Hartigová se sídlem Doksy, Mimoňská 384, PSČ 47201,
        IČ: 15531066, Česká republika (dále jako „zpracovatel“).
      </p>
      <p>
        Svůj souhlas poskytuji pro účely zpracování rezervace, elektronické
        komunikace, tj. poskytování informací elektronickou poštou či
        telefonicky, zasílání obchodních sdělení prostřednictvím elektronických
        prostředků dle zákona č. 480/2004 Sb., o některých službách informační
        společnosti, v platném znění.
      </p>
      <h3>Osobní údaje bude správce zpracovávat manuálně i automaticky.</h3>
      <p>
        Beru tímto na vědomí, že správce je na moji žádost povinen poskytnout mi
        informaci o zpracování mých osobních údajů, o jeho účelu, o osobních
        údajích, které jsou předmětem zpracování, včetně informací o jejich
        zdroji, povaze případného automatizovaného zpracování a o příjemcích
        osobních údajů, tedy o těch, komu byly mé osobní údaje zpřístupněny.
      </p>
      <p>
        Dále beru na vědomí, že mám právo požadovat po správci vysvětlení
        zpracování svých osobních údajů v případě, že bych zjistil nebo bych se
        domníval, že toto zpracování neprobíhá řádným způsobem, a dále právo
        požadovat po správci nápravu tohoto stavu spočívající zejm. v blokování,
        provedení opravy, doplnění nebo likvidaci osobních údajů.
      </p>
      <p>
        Svůj souhlas poskytuji dobrovolně a beru na vědomí, že mám právo jej
        kdykoliv odvolat. Tento souhlas uděluji na dobu 60 měsíců od jeho
        udělení.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GdprModal"
};
</script>

<style type="sass">
.modal__close {
  color: #fff;
  background-color: #ed2b2b;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  padding: 10px 30px;
  z-index: 1000;
}
.v--modal {
  border-radius: 0 !important;
  z-index: 1000;
  margin-top: 50px;
}
.modal-body {
  padding: 20px;
}
.modal-body p {
  font-size: 14px;
}
.modal-body h3 {
  margin: 1.5rem 0 0.5rem;
}
</style>
