import "es6-promise/auto";

import Vue from "vue";
import VModal from "vue-js-modal";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VModal, { dynamic: true, injectModalsContainer: true });

Vue.use(VueAxios, axios);

import ContactForm from "../contact_form.vue";
import ReservationForm from "../reservation_form.vue";

import "../lib/vee-validate";

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    el: "#app",
    components: { ContactForm, ReservationForm }
  });

  VModal.rootInstance = app;
});
