<template>
  <div class="nested-fields">
    <div class="residents__row">
      <div class="row">
        <div class="col-sm-3 col-md-4">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="jméno a příjmení"
            :vid="`firstname-${identifier}`"
          >
            <div
              class="form-group adaptive-placeholder string required full_name"
              :class="{ 'has-error': errors[0] }"
            >
              <div class="form-control-wrapper">
                <input
                  id="full_name"
                  :value="full_name"
                  class="form-control empty string required indent-name"
                  type="text"
                  name="contact[full_name]"
                  :class="{ 'not-empty': full_name }"
                  @input="$emit('update:full_name', $event.target.value)"
                />
                <label
                  class="control-label string required"
                  tag="div"
                  for="full_name"
                >
                  <abbr title="required">*</abbr> Jméno a příjmení
                </label>
                <span class="input-error help-block">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-5 col-md-4">
          <div class="form-group date required reservation_residents_birthdate">
            <label
              class="control-label date required date-select"
              for="birthdate"
              ><abbr title="required">*</abbr> Datum narození</label
            >
            <DateDropdown
              :min="(new Date().getFullYear() - 100).toString()"
              :max="new Date().getFullYear().toString()"
              :default="new Date().toString()"
              select-class-name="form-control date"
              container-class-name="form-inline"
              @input="$emit('update:birthdate', $event)"
            />
          </div>
        </div>
        <div class="col-sm-2 col-md-2">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            name="stravování"
            :vid="`catering-${identifier}`"
          >
            <div
              class="form-group select required reservation_residents_catering"
              :class="{ 'has-error': errors[0] }"
            >
              <label class="control-label select required" for="catering"
                ><abbr title="required">*</abbr> Stravování</label
              >
              <div class="form-control-wrapper">
                <select
                  id="catering"
                  class="form-control select required"
                  :value="catering"
                  @change="$emit('update:catering', $event.target.value)"
                >
                  <option value="">Vyberte formu stravování</option>
                  <option value="no_catering">bez stravy</option>
                  <option value="breakfast">snídaně</option>
                  <option value="lunch">oběd</option>
                  <option value="dinner">večeře</option>
                  <option value="half_board">polopenze</option>
                  <option value="full_board">plná penze</option></select
                >
                <span class="input-error help-block">{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-2 col-md-2">
          <div class="form-group-indent">
            <a
              class="btn btn-sm btn-block btn-default"
              href="#"
              @click.prevent="removeResident"
              >Odstranit</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateDropdown from "./DateDropdown.vue";

export default {
  name: "Resident",
  components: {
    DateDropdown
  },
  props: ["identifier", "full_name", "catering", "birthdate"],
  methods: {
    removeResident() {
      this.$emit("resident-remove", this.identifier);
    }
  }
};
</script>
