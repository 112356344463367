<template>
  <div class="price-calculation">
    <div v-if="canDisplayPriceInfo">
      <div v-if="canDisplayTotals">
        <h3 class="text-center">
          <span class="gray"
            >Přibližná cena ubytování na {{ totalDays }} nocí je:</span
          >
          {{ totalPrice | formatPrice }}
        </h3>
        <ul class="list-unstyled text-center">
          <li>
            <strong>Ubytování</strong>:
            <strong class="highlight">{{
              totalAccomodation | formatPrice
            }}</strong>
          </li>
          <li>
            <strong>Strava</strong>:
            <strong class="highlight">{{ totalFood | formatPrice }}</strong>
          </li>
          <li>
            <strong>Neobsazená lůžka</strong>:
            <strong class="highlight">{{
              totalUnoccupiedBeds | formatPrice
            }}</strong>
          </li>
          <li>
            <strong>Služby</strong>:
            <strong class="highlight">{{ totalServices | formatPrice }}</strong>
          </li>
          <li>
            <strong>Poplatky městu</strong>:
            <strong class="highlight">{{ totalCharge | formatPrice }}</strong>
          </li>
        </ul>
      </div>
      <div v-else>
        <h3 class="text-center">
          <span class="gray">Přibližná cena ubytování nezle spočítat!</span>
          <br />
          Vyplňte prosím rezeravční formulář validními daty.
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { parse, isFuture, differenceInCalendarYears } from "date-fns";
const calculateAge = birthday => {
  const birthDate = parse(birthday, "dd.mm.yyyy", new Date());
  if (isFuture(birthDate)) {
    return 0;
  }

  return differenceInCalendarYears(new Date(), birthDate);
};

const snakeToCamel = str =>
  str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace("-", "")
      .replace("_", "")
  );

export default {
  name: "TotalPrice",
  filters: {
    formatPrice: function(value) {
      return new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: "CZK",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value);
    }
  },
  props: [
    "full_name",
    "phone",
    "email",
    "city",
    "street",
    "house_number",
    "zip",
    "arrival_date",
    "departure_date",
    "persons_no",
    "cabins_no",
    "comment",
    "residents",
    "services"
  ],
  data: () => ({
    prices: {}
  }),

  computed: {
    totalUnoccupiedBeds: function() {
      const totalPlaces = this.cabins_no * 4;
      const sum =
        (totalPlaces - this.persons_no) *
        this.prices.unoccupiedBed *
        this.totalDays;

      return Math.max(0, sum);
    },
    totalDays: function() {
      const diffTime = Math.abs(
        Date.parse(this.arrival_date) - Date.parse(this.departure_date)
      );
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    totalAccomodation: function() {
      if (this.totalDays < 7) {
        return this.persons_no * this.totalDays * this.prices.accoLess7Days;
      } else {
        return this.persons_no * this.totalDays * this.prices.accoMore7Days;
      }
    },
    totalFood: function() {
      const obj = this.residents.map(resident => {
        const age = calculateAge(resident.birthdate);
        const catering = resident.catering;

        const prefix = age < 18 ? "child" : "adult";
        const cateringPriceIndex = snakeToCamel(`${prefix}_${catering}`);
        return (this.prices[cateringPriceIndex] || 0) * this.totalDays;
      });

      return obj.reduce((a, b) => a + b, 0);
    },
    totalAdults: function() {
      if (this.residents.length) {
        const obj = this.residents.map(resident => {
          const age = calculateAge(resident.birthdate);
          return age < 18 ? 0 : 1;
        });

        return obj.reduce((a, b) => a + b, 0);
      } else {
        return parseInt(this.persons_no);
      }
    },
    totalPersons: function() {
      if (this.residents.length) {
        return this.residents.length;
      } else {
        return parseInt(this.persons_no);
      }
    },

    totalServices: function() {
      return this.services
        .map(
          service =>
            (this.prices[`${service}Rent`] || this.prices[service] || 0) *
            this.totalDays
        )
        .reduce((a, b) => a + b, 0);
    },
    totalCharge: function() {
      return this.totalAdults * this.prices.cityFee * this.totalDays;
    },
    totalPrice: function() {
      return (
        this.totalCharge +
        this.totalFood +
        this.totalAccomodation +
        this.totalUnoccupiedBeds +
        this.totalServices
      );
    },
    canDisplayTotals: function() {
      return !Number.isNaN(this.totalPrice);
    },

    canDisplayPriceInfo: function() {
      return this.totalDays && this.totalPersons;
    }
  },

  mounted() {
    this.axios.get("/api/v1/pricelists").then(response => {
      this.prices = response.data;
    });
  }
};
</script>
