<template>
  <div id="app">
    <div v-if="!submitted">
      <form
        id="new_reservation"
        class="new_reservation"
        @submit.prevent="submit()"
      >
        <ValidationObserver ref="observer">
          <input name="utf8" type="hidden" value="✓" />
          <div class="reservation-form-wrapper">
            <div class="reservation-form">
              <fieldset>
                <legend>Kontaktní informace</legend>
                <div class="row">
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="jméno a příjmení"
                    >
                      <div
                        class="form-group adaptive-placeholder string required reservation_full_name"
                        :class="{ 'has-error': errors[0] }"
                      >
                        <div class="form-control-wrapper">
                          <input
                            id="reservation_full_name"
                            v-model="form.full_name"
                            class="form-control empty string required"
                            type="text"
                            name="reservation[full_name]"
                            :class="{ 'not-empty': form.full_name }"
                          />
                          <label
                            class="control-label string required"
                            tag="div"
                            for="reservation_full_name"
                          >
                            <abbr title="required">*</abbr> Jméno a příjmení
                          </label>
                          <span class="input-error help-block">{{
                            errors[0]
                          }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="telefonní číslo"
                    >
                      <div
                        class="form-group adaptive-placeholder tel optional reservation_phone"
                        :class="{ 'has-error': errors[0] }"
                      >
                        <div class="form-control-wrapper">
                          <input
                            id="reservation_phone"
                            v-model="form.phone"
                            class="form-control empty string tel optional"
                            type="tel"
                            name="reservation[phone]"
                            :class="{ 'not-empty': form.phone }"
                          />
                          <label
                            class="control-label tel optional"
                            tag="div"
                            for="reservation_phone"
                            >Telefonní číslo</label
                          >
                          <span class="input-error help-block">{{
                            errors[0]
                          }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="e-mailová adresaí"
                    >
                      <div
                        class="form-group adaptive-placeholder email required reservation_email"
                        :class="{ 'has-error': errors[0] }"
                      >
                        <div class="form-control-wrapper">
                          <input
                            id="reservation_email"
                            v-model="form.email"
                            class="form-control empty string email required"
                            type="email"
                            name="reservation[email]"
                            :class="{ 'not-empty': form.email }"
                          />
                          <label
                            class="control-label email required"
                            tag="div"
                            for="reservation_email"
                          >
                            <abbr title="required">*</abbr> E-mailová
                            adresa</label
                          >
                          <span class="input-error help-block">{{
                            errors[0]
                          }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Bydliště</legend>
                <div class="row">
                  <div class="col-md-4">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="město / obec"
                    >
                      <div
                        class="form-group adaptive-placeholder string required reservation_city"
                        :class="{ 'has-error': errors[0] }"
                      >
                        <div class="form-control-wrapper">
                          <input
                            id="reservation_city"
                            v-model="form.city"
                            class="form-control empty string required"
                            type="text"
                            name="reservation[city]"
                            :class="{ 'not-empty': form.city }"
                          />
                          <label
                            class="control-label string required"
                            tag="div"
                            for="reservation_city"
                          >
                            <abbr title="required">*</abbr> Město / obec</label
                          >
                          <span class="input-error help-block">{{
                            errors[0]
                          }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-3">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="ulice"
                    >
                      <div
                        class="form-group adaptive-placeholder string optional reservation_street"
                        :class="{ 'has-error': errors[0] }"
                      >
                        <div class="form-control-wrapper">
                          <input
                            id="reservation_street"
                            v-model="form.street"
                            class="form-control empty string optional"
                            type="text"
                            name="reservation[street]"
                            :class="{ 'not-empty': form.street }"
                          />
                          <label
                            class="control-label string optional"
                            tag="div"
                            for="reservation_street"
                            >Ulice</label
                          >

                          <span class="input-error help-block">{{
                            errors[0]
                          }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-2">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules=""
                      name="číslo popisné"
                    >
                      <div
                        class="form-group adaptive-placeholder string optional reservation_house_number"
                        :class="{ 'has-error': errors[0] }"
                      >
                        <div class="form-control-wrapper">
                          <input
                            id="reservation_house_number"
                            v-model="form.house_number"
                            class="form-control empty string optional"
                            type="text"
                            name="reservation[house_number]"
                            :class="{ 'not-empty': form.house_number }"
                          />
                          <label
                            class="control-label string optional"
                            tag="div"
                            for="reservation_house_number"
                            >Číslo popisné</label
                          >

                          <span class="input-error help-block">{{
                            errors[0]
                          }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-3">
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required"
                      name="psč"
                    >
                      <div
                        class="form-group adaptive-placeholder string required reservation_zip"
                        :class="{ 'has-error': errors[0] }"
                      >
                        <div class="form-control-wrapper">
                          <input
                            id="reservation_zip"
                            v-model="form.zip"
                            class="form-control empty string required"
                            type="text"
                            name="reservation[zip]"
                            :class="{ 'not-empty': form.zip }"
                          />
                          <label
                            class="control-label string required"
                            tag="div"
                            for="reservation_zip"
                          >
                            <abbr title="required">*</abbr> Poštovní směrovací
                            číslo</label
                          >

                          <span class="input-error help-block">{{
                            errors[0]
                          }}</span>
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </fieldset>
              <div class="row">
                <div class="col-md-6">
                  <fieldset>
                    <legend>Doba pobytu</legend>
                    <div class="row">
                      <div class="col-md-6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          name="datum přijezdu"
                        >
                          <div
                            class="form-group adaptive-placeholder string required reservation_arrival_date"
                            :class="{ 'has-error': errors[0] }"
                          >
                            <div class="form-control-wrapper">
                              <DatePick
                                v-model="form.arrival_date"
                                :display-format="'DD.MM.YYYY'"
                                :is-date-disabled="minArrivedDate"
                                :input-attributes="{
                                  class:
                                    'form-control empty string required not-empty'
                                }"
                                :weekdays="datePicker.weekdays"
                                :months="datePicker.months"
                              />
                              <label
                                class="control-label string required"
                                :class="{ 'not-empty': form.arrival_date }"
                                tag="div"
                                for="reservation_arrival_date"
                              >
                                <abbr title="required">*</abbr> Datum
                                přijezdu</label
                              >

                              <span class="input-error help-block">{{
                                errors[0]
                              }}</span>
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          name="datum odjezdu"
                        >
                          <div
                            class="form-group adaptive-placeholder string required reservation_departure_date"
                            :class="{ 'has-error': errors[0] }"
                          >
                            <div class="form-control-wrapper">
                              <DatePick
                                v-model="form.departure_date"
                                :is-date-disabled="minDepartureDate"
                                :display-format="'DD.MM.YYYY'"
                                :input-attributes="{
                                  class:
                                    'form-control empty string required not-empty'
                                }"
                                :class="{ 'not-empty': form.departure_date }"
                                :weekdays="datePicker.weekdays"
                                :months="datePicker.months"
                              />

                              <label
                                class="control-label string required"
                                tag="div"
                                :class="{ 'not-empty': form.departure_date }"
                                for="reservation_departure_date"
                              >
                                <abbr title="required">*</abbr> Datum
                                odjezdu</label
                              >

                              <span class="input-error help-block">{{
                                errors[0]
                              }}</span>
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                  </fieldset>
                </div>
                <div class="col-md-6">
                  <fieldset>
                    <legend>Počet chatek a osob</legend>
                    <div class="row">
                      <div class="col-md-6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          name="počet osob"
                        >
                          <div
                            class="form-group adaptive-placeholder integer optional reservation_persons_no"
                            :class="{ 'has-error': errors[0] }"
                          >
                            <div class="form-control-wrapper">
                              <input
                                id="reservation_persons_no"
                                v-model="form.persons_no"
                                class="form-control empty numeric integer optional"
                                type="number"
                                step="1"
                                name="reservation[persons_no]"
                                :class="{ 'not-empty': form.persons_no }"
                              />
                              <label
                                class="control-label integer optional"
                                tag="div"
                                for="reservation_persons_no"
                                >Počet osob</label
                              >

                              <span class="input-error help-block">{{
                                errors[0]
                              }}</span>
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          name="počet chatek"
                        >
                          <div
                            class="form-group adaptive-placeholder integer required reservation_cabins_no"
                            :class="{ 'has-error': errors[0] }"
                          >
                            <div class="form-control-wrapper">
                              <input
                                id="reservation_cabins_no"
                                v-model="form.cabins_no"
                                class="form-control empty numeric integer required"
                                type="number"
                                step="1"
                                name="reservation[cabins_no]"
                                :class="{ 'not-empty': form.cabins_no }"
                              />
                              <label
                                class="control-label integer required"
                                tag="div"
                                for="reservation_cabins_no"
                              >
                                <abbr title="required">*</abbr> Počet
                                chatek</label
                              >

                              <span class="input-error help-block">{{
                                errors[0]
                              }}</span>
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="row reservation-form__indent">
                <div class="col-md-7">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules=""
                    name="poznámka"
                  >
                    <div
                      class="form-group adaptive-placeholder text optional reservation_comment"
                      :class="{ 'has-error': errors[0] }"
                    >
                      <div class="form-control-wrapper">
                        <textarea
                          id="reservation_comment"
                          v-model="form.comment"
                          class="form-control empty text optional reservation-comment"
                          name="reservation[comment]"
                          :class="{ 'not-empty': form.comment }"
                        >
                        </textarea>
                        <label
                          class="control-label text optional"
                          tag="div"
                          for="reservation_comment"
                          >Poznámka</label
                        >

                        <span class="input-error help-block">{{
                          errors[0]
                        }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-md-5">
                  <div class="reservation-form__note">
                    <p>
                      Chcete-li zjednodušit přůběh rezevace, spočítat přesnější
                      cenu pobytu. Přidejte prosím upřesňující informace
                      o&nbsp;ubytovaných a&nbsp;zvolte stravování.
                    </p>
                    <p>
                      <a
                        class="btn btn-sm btn-primary"
                        href="#"
                        @click.prevent="addResident"
                        >Přidat upřesňující informace</a
                      >
                    </p>
                  </div>
                </div>
              </div>
              <fieldset class="residents__wrapper">
                <legend v-if="form.residents.lenght">
                  Upřesňující informace o ubytovaných
                </legend>
                <div v-for="resident in form.residents" :key="resident.id">
                  <Resident
                    v-bind.sync="resident"
                    @resident-remove="removeResident"
                  />
                </div>
                <div class="residents"></div>
              </fieldset>
              <fieldset class="reservation-form__indent">
                <legend class="text-center">
                  Máte zájem o některou z našich služeb?
                </legend>
                <div
                  class="form-group check_boxes optional reservation_services checkboxes-inline"
                >
                  <span class="checkbox">
                    <input
                      id="reservation_services_fridge"
                      v-model="form.services"
                      class="check_boxes optional"
                      type="checkbox"
                      value="fridge"
                      name="reservation[services][]"
                    />
                    <label
                      class="collection_check_boxes"
                      for="reservation_services_fridge"
                      >Chatka s ledničkou</label
                    >
                  </span>
                  <span class="checkbox">
                    <input
                      id="reservation_services_cooker"
                      v-model="form.services"
                      class="check_boxes optional"
                      type="checkbox"
                      value="cooker"
                      name="reservation[services][]"
                    />
                    <label
                      class="collection_check_boxes"
                      for="reservation_services_cooker"
                      >Chatka s vařičem</label
                    >
                  </span>
                  <span class="checkbox">
                    <input
                      id="reservation_services_grill"
                      v-model="form.services"
                      class="check_boxes optional"
                      type="checkbox"
                      value="grill"
                      name="reservation[services][]"
                    />
                    <label
                      class="collection_check_boxes"
                      for="reservation_services_grill"
                      >Zapůjčení grilu</label
                    >
                  </span>
                  <span class="checkbox">
                    <input
                      id="reservation_services_kettle"
                      v-model="form.services"
                      class="check_boxes optional"
                      type="checkbox"
                      value="kettle"
                      name="reservation[services][]"
                    />
                    <label
                      class="collection_check_boxes"
                      for="reservation_services_kettle"
                      >Zapůjčení varné konvice</label
                    >
                  </span>
                  <span class="checkbox">
                    <input
                      id="reservation_services_parking"
                      v-model="form.services"
                      class="check_boxes optional"
                      type="checkbox"
                      value="parking"
                      name="reservation[services][]"
                    />
                    <label
                      class="collection_check_boxes"
                      for="reservation_services_parking"
                      >Prakování vozu/motorky</label
                    >
                  </span>
                </div>
              </fieldset>
              <TotalPrice v-bind="form" />
              <div class="text-center">
                <Gdpr identifier="booking" />
              </div>
              <div class="text-center">
                <input
                  type="submit"
                  name="commit"
                  value="Odeslat nezávaznou rezervaci"
                  class="btn btn-lg btn-primary"
                />
              </div>
            </div>
          </div>
        </ValidationObserver>
      </form>
    </div>
    <div v-else>
      <h2 class="text-center">Děkujeme, rezervace už letí k nám!</h2>
    </div>
  </div>
</template>

<script>
import Resident from "components/Resident.vue";
import Gdpr from "components/Gdpr.vue";
import TotalPrice from "components/TotalPrice.vue";
import DatePick from "vue-date-pick";

export default {
  name: "ReservationForm",
  components: { Resident, DatePick, Gdpr, TotalPrice },
  data: () => ({
    datePicker: {
      weekdays: ["Po", "Út", "St", "Čt", "Pá", "So", "Ne"],
      months: [
        "leden",
        "únor",
        "březen",
        "duben",
        "květen",
        "červen",
        "červenec",
        "srpen",
        "září",
        "říjen",
        "listopad",
        "prosinec"
      ]
    },
    submitted: false,
    submitError: null,
    form: {
      full_name: "",
      phone: "",
      email: "",
      city: "",
      street: "",
      house_number: "",
      zip: "",
      arrival_date: "",
      departure_date: "",
      persons_no: "",
      cabins_no: "",
      comment: "",
      residents: [],
      services: []
    }
  }),
  computed: {
    dataForSubmit: function() {
      const formData = {};
      Object.assign(formData, this.form);

      formData.residents.forEach(resident => delete resident.identifier);

      formData.residents_attributes = formData.residents;
      delete formData.residents;

      return { reservation: formData };
    }
  },
  methods: {
    minArrivedDate(date) {
      const currentDate = new Date();
      return date < currentDate || date >= Date.parse(this.form.departure_date);
    },
    minDepartureDate(date) {
      return date <= Date.parse(this.form.arrival_date);
    },
    removeResident(identifier) {
      const index = this.form.residents.findIndex(
        resident => resident.identifier === identifier
      );

      this.form.residents.splice(index, 1);
    },
    addResident() {
      const emptyResident = {
        identifier: Date.now(),
        full_name: "",
        birthdate: "",
        catering: ""
      };

      this.form.residents.push(emptyResident);
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.submitError = null;
        this.axios.defaults.headers.common[
          "X-CSRF-Token"
        ] = document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content");

        this.axios
          .post("/api/v1/reservations", this.dataForSubmit)
          .then(() => {
            this.submitted = true;
          })
          .catch(() => {
            this.submitError = true;
          });
      } else {
        const firstErrorElement = $(".reservation-form")
          .find(".has-error")
          .first();

        const offset = firstErrorElement.offset().top;

        $("html, body").animate({ scrollTop: offset - 120 }, 1000);
      }
    }
  }
};
</script>

<style lang="scss">
$vdpColor: #ed2b2b;

@import "vue-date-pick/src/vueDatePick.scss";

.vdpWithInput {
  width: 100%;
}
</style>
