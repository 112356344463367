var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(!_vm.submitted)?_c('div',[_c('form',{staticClass:"new_contact",attrs:{"id":"new_contact"},on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('ValidationObserver',{ref:"observer"},[_c('input',{attrs:{"name":"utf8","type":"hidden","value":"✓"}}),_vm._v(" "),_c('div',{staticClass:"contact-form-wrapper"},[_c('div',{staticClass:"contact-form"},[_c('div',{staticClass:"row row--spaced-xs"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"jméno a příjmení"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group adaptive-placeholder string required name",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"form-control-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control empty string required",class:{ 'not-empty': _vm.form.name },attrs:{"id":"name","type":"text","name":"contact[name]"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"control-label string required",attrs:{"tag":"div","for":"name"}},[_c('abbr',{attrs:{"title":"required"}},[_vm._v("*")]),_vm._v(" Jméno a příjmení\n                      ")]),_vm._v(" "),_c('span',{staticClass:"input-error help-block"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,false,3775335071)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":"e-mailová adresa"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group adaptive-placeholder email optional email",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"form-control-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control empty string email optional",class:{ 'not-empty': _vm.form.email },attrs:{"id":"email","type":"email","name":"contact[email]"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"control-label email optional",attrs:{"tag":"div","for":"email"}},[_vm._v("E-mailová adresa")]),_vm._v(" "),_c('span',{staticClass:"input-error help-block"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,false,1586106753)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","name":"telefnoní číslo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group adaptive-placeholder tel optional phone",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"form-control-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],staticClass:"form-control empty string tel optional",class:{ 'not-empty': _vm.form.phone },attrs:{"id":"phone","type":"tel","name":"contact[phone]"},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"control-label tel optional",attrs:{"tag":"div","for":"phone"}},[_vm._v("Telefnoní číslo")]),_vm._v(" "),_c('span',{staticClass:"input-error help-block"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,false,216277156)})],1),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"zpráva"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group adaptive-placeholder text required message",class:{ 'has-error': errors[0] }},[_c('div',{staticClass:"form-control-wrapper"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"form-control empty text required",class:{ 'not-empty': _vm.form.message },attrs:{"id":"message","rows":"6","name":"contact[message]"},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "message", $event.target.value)}}}),_vm._v(" "),_c('label',{staticClass:"control-label text required",attrs:{"tag":"div","for":"message"}},[_c('abbr',{attrs:{"title":"required"}},[_vm._v("*")]),_vm._v(" Zpráva\n                      ")]),_vm._v(" "),_c('span',{staticClass:"input-error help-block"},[_vm._v(_vm._s(errors[0]))])])])]}}],null,false,1646051080)}),_vm._v(" "),_c('div',{staticClass:"text-left"},[_c('Gdpr',{attrs:{"identifier":"contact"}})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"form-group--btn"},[_c('input',{staticClass:"btn btn-primary",attrs:{"type":"submit","name":"commit","value":"Odeslat kontaktní formulář"}})])])])])],1)]):_c('div',[_c('h2',[_vm._v("Děkujeme, zpráva k nám už je na cestě!")])])])}
var staticRenderFns = []

export { render, staticRenderFns }