import Vue from 'vue';
import {
   ValidationProvider, ValidationObserver, localize
} from 'vee-validate/dist/vee-validate.full';

import cs from 'vee-validate/dist/locale/cs.json';

localize('cs', cs);
localize('cs');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
