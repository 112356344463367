<template>
  <div class="gdpr">
    <ValidationProvider
      v-slot="{ errors }"
      :rules="{ required: { allowFalse: false } }"
      name="souhlas se zpracováním osobních údajů"
    >
      <span class="checkbox">
        <input
          :id="`agreement-${identifier}`"
          v-model="agreement"
          class="check_boxes optional"
          type="checkbox"
          :name="`agreement-${identifier}`"
        />
        <label class="collection_check_boxes" :for="`agreement-${identifier}`"
          >Souhlasím se
          <a href="#" @click.prevent="showModal"
            >zpracovaním osobních údajů</a
          ></label
        >
      </span>
      <span class="input-error help-block">{{ errors[0] }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
import GdprModal from "./GdprModal";

export default {
  name: "Gdpr",
  props: ["identifier"],
  data() {
    return {
      agreement: ""
    };
  },
  methods: {
    showModal() {
      this.$modal.show(
        GdprModal,
        {},
        {
          adaptive: "true",
          width: "100%",
          height: "auto",
          maxHeight: "80%",
          maxWidth: "1200",
          scrollable: "true"
        }
      );
    }
  }
};
</script>
